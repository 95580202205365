// import { YooKassa, CurrencyEnum, ConnectorOpts } from 'yookassa-sdk';
import { YooCheckout } from '@a2seven/yoo-checkout'; // OR const { YooCheckout } = require('@a2seven/yoo-checkout');
import axios from 'axios';
// import crypto from 'crypto';
import uuid from 'react-uuid'

const initOpts = {
    // debug: true,
    secretKey: `${process.env.REACT_APP_YOOKASSA_SECRET_KEY}`,
    shopId: `${process.env.REACT_APP_YOOKASSA_ACCOUNT_ID}`,
};
const idempotenceKey = '02347fc4-a1f0-49db-807e-f0d62c2ed5a5';
const sdk = new YooCheckout({ shopId: `${process.env.REACT_APP_YOOKASSA_ACCOUNT_ID}`, secretKey: `${process.env.REACT_APP_YOOKASSA_SECRET_KEY}`});
const urlPayment = `${process.env.REACT_APP_API_PAYMENT}/api/v1/payment/yookassa/createPayment`;

// async function createPayment(data) {
//     try {
//         const response = await sdk.createPayment(data, idempotenceKey).catch(console);
//         return response;
//       } catch (error) {
//         console.log(error)
//         return null;
//       }
// }

export const handlePurchaseAction = (subscriptionPlan, userData, email, option, setModalLink) => {
    // console.log(`${process.env.REACT_APP_YOOKASSA_SECRET_KEY}`,`${process.env.REACT_APP_YOOKASSA_ACCOUNT_ID}`,)
    const price = option.priceFocus
    const duration = option.duration
    const renewalsPerformed = 0
    const uuidIdempotenceKey = uuid()

    const subscriptionPlanTier = subscriptionPlan.subscriptionPlanTier
    const username = userData.username
    const userId = userData.id
    const referralCode = 'test'
    const referralCodeValue = 0.0
    const bonusPoints = 0
    const userEmail = email 
    const name = subscriptionPlan.name
    const data = {
        "amount": {
            "value": price,
            "currency": "RUB"
        },
        "confirmation": {
            "type": "redirect",
            "return_url": "https://t.me/yostory_dev_bot"
        },
        "capture": true,
        "description": "Заказ подписки",
        "metadata": {
            "username": username,
            "user_id": userId,
            "subscription_plan_tier": subscriptionPlanTier,
            "referral_code": referralCode,
            "referral_code_value": referralCodeValue,
            "bonus_points": bonusPoints,
            "renewal_period_days": duration,
            "renewals_performed": renewalsPerformed,
            "duration": duration,
        },
        "receipt": {
            "customer": {
                "email": userEmail,
            },
            "items": [
                {
                    "description": name,
                    "quantity": "1.00",
                    "measure": "piece",
                    "amount": {
                        "value": price,
                        "currency": 'RUB'
                    },
                    "tax_system_code": "2",
                    "vat_code": "1",
                    "payment_mode": "full_payment",
                    "payment_subject": "service",
                    "country_of_origin_code": "RU",
                },
            ],
            "settlements": [
                {
                    "type": "cashless",
                    "amount": {
                        "value": price,
                        "currency": 'RUB'
                    },
                }
            ]
        }
    }
    // const paymentResult = createPayment(data);
    const urlConfirmation = axios.post(urlPayment, data, {
        headers: {
            'Idempotence-Key': uuidIdempotenceKey,
            'Content-Type': 'application/json'
        }
    })
    .then(function (response) {
      console.log(response);
      const urlConfirmation = response.data.result.confirmation.confirmation_url;
      console.log(urlConfirmation);
      setModalLink(urlConfirmation);
      return urlConfirmation
    })
    .catch(function (error) {
      console.log(error);
      setModalLink(null);
      return null
    });
    // console.log('Payment created: ', paymentResult);
    return urlConfirmation 
}

