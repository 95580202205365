import React from "react";
import styled from "styled-components";
import Ripples from 'react-ripples';

import Button from 'react-bootstrap/Button';

export const ButtonGradient = styled(Button)`
  cursor: pointer;
  width: 80%;
  height: 4em;
  display: flex;
  align-items: center;
  justify-content: center;

  line-height: normal;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(270deg, rgb(70, 196.17, 232) 26.4%, rgb(203, 75, 227) 100%);
  border-radius: 1em;
  padding: 0.5em;
  color: #ffffff;
  font-size: 1.2em;
  font-family: "MontserratAlternates";
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  border: 0px;

  &:hover {
    background: yellow;
    color: #000000;
  };

  &:active {
    background: rgb(203, 75, 227) !important;
    color: #ffffff;
  };
  `;


  export const ButtonSmallGradient = styled(Button)`
  cursor: pointer;
  
  line-height: normal;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(270deg, rgb(70, 196.17, 232) 26.4%, rgb(203, 75, 227) 100%);
  border-radius: 1em;
  padding: 0.5em;
  color: #ffffff;
  font-size: 1.2em;
  font-family: "MontserratAlternates";
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  border: 0px;

  &:hover {
    background: yellow;
    color: #000000;
  };

  &:active {
    background: rgb(203, 75, 227) !important;
    color: #ffffff;
  };
  `;

// export function ButtonGradient(props) {
//   return (
//     <Ripples>
//       <ButtonGradientElement {...props}/>
//     </Ripples>
//   );
// };

export const ButtonGradientNavbar = styled(Button)`
  cursor: pointer;
  width: 6em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;

  line-height: normal;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(270deg, rgb(70, 196.17, 232) 26.4%, rgb(203, 75, 227) 100%);
  border-radius: 1em;
  padding: 0.5em;
  color: #ffffff;
  font-size: 1.2em;
  font-family: "MontserratAlternates";
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  border: 0px;
  `;

export const ButtonRadial= styled(Button)`
  cursor: pointer;
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;

  line-height: normal;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(270deg, rgb(70, 196.17, 232) 26.4%, rgb(203, 75, 227) 100%);
  border-radius: 50%;
  padding: 0.5em;
  color: #ffffff;
  font-size: 1.2em;
  font-family: "MontserratAlternates";
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  border: 0px;
`;

export const ButtonSubscriptionChoice = styled(Button)`
  cursor: pointer;
  width: 100%;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  
  line-height: normal;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  border-radius: 1em;
  padding: 0.5em;
  color: #000000;
  font-size: 1.2em;
  font-family: "MontserratAlternates";
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  border: 0px;

  &:hover {
    background-color: yellow;
    color: #000000;
  };

  &:active {
    background-color: rgb(203, 75, 227) !important;
    background: rgb(203, 75, 227) !important;
  };

  &:disabled {
    background: rgb(211, 211, 211) !important;
  }
  `;