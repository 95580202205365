import * as React from 'react';
import axios from 'axios';

import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';

import Button from 'react-bootstrap/Button';
import { ButtonGradient } from '../../elements/Buttons';
import { Link } from 'react-router-dom';
import { TopHeader, SectionHeader, ParagraphTextShort} from '../../elements/Text';

// const API_ENDPOINT_DETECT_IMAGE = `${process.env.REACT_APP_API_HOST_IP}/api/v1/detect/image`

const Account = ({userData}) => {
 
  return (
    <>
      <Stack gap={2} className="col-md-5 mx-auto justify-content-between" style={{ height: '60vh', display: 'flex', direction: 'column', justifyContent: 'space-between'}}>
        <center>
          <TopHeader>Мой аккаунт</TopHeader>
        </center>
        <div style={{ height: '30vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
        <SectionHeader>Добро пожаловать, @<strong>{userData.username}</strong>. У Вас пока нет подписки</SectionHeader>
        {/* <SectionHeader>У Вас пока нет подписки</SectionHeader> */}
        <ButtonGradient as={Link} to="/subscription" variant="primary" >Оформить подписку</ButtonGradient>
        </div>
      </Stack>
      {/* <Button variant="primary" type="submit">
        Удалить аккаунт
      </Button> */}
    </>
  );
}

export default Account;