import * as React from 'react';
import styled from "styled-components";

import axios from 'axios';

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';

import Button from 'react-bootstrap/Button';
import { TopHeader, SectionHeader, ParagraphText, ParagraphTextShort} from '../../elements/Text';
import { ButtonSubscriptionChoice } from '../Buttons';

const SubscriptionCardContainer = styled(Card)`
  color: #ffffff;
  border-radius: 1em;
  padding: 0.5em;
  border: 0px;
  background: linear-gradient(
    211.56deg,
    rgba(70, 196, 232, 1) 0.4416228737682104%,
    rgba(203, 75, 227, 1) 100%
  );
  font-size: 1.2em;
  font-family: "MontserratAlternates";
  font-weight: bold;
  text-decoration: none;
`
const SubscriptionOptions = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`
const SubscriptionDescription = styled.div`
  display: flex;
  align-items: center;
`

const SubscriptionNumber = styled.div`
  font-size: 1.6em;
  font-weight: bold;
  padding-left: 1em;
  padding-right: 1em;
`
const SubscriptionPrice = styled.div`
  display: flex;
  flex-direction: column;
`

const SubscriptionPriceFocus = styled.div`
  color: #9D75E5;
`

const SubscriptionPriceOutOfFocus = styled.div`
  color: #BDBBBB;
  font-size: 0.8em;
  text-decoration: line-through;
`


const dummySubscriptionPlanCheap = {
  uuid: null,
  name: 'Тестовая подписка',
  subscriptionPlanTier: 1,
  numberOfTexts: 30,
  numberOfAudios: 30,
  options: [
    {
      name: '1 месяц',
      duration: 30,
      priceFocus: 10,
    },
    {
      name: '3 месяца',
      duration: 90,
      priceFocus: 30,
    },
    {
      name: '12 месяцев',
      duration: 360,
      priceFocus: 120,
    }
  ]
};


const dummySubscriptionPlanBasic = {
  uuid: null,
  name: 'Базовая подписка',
  subscriptionPlanTier: 1,
  numberOfTexts: 30,
  numberOfAudios: 30,
  options: [
    {
      name: '1 месяц',
      duration: 30,
      priceFocus: 499,
      priceOutOfFocus: 599,
    },
    {
      name: '3 месяца',
      duration: 90,
      priceFocus: 1399,
      priceOutOfFocus: 1599,
    },
    {
      name: '12 месяцев',
      duration: 360,
      priceFocus: 5499,
      priceOutOfFocus: 6599,
    }
  ]
};

const dummySubscriptionPlanAdvanced = {
  uuid: null,
  name: 'Продвинутая подписка',
  subscriptionPlanTier: 2,
  numberOfTexts: 60,
  numberOfAudios: 60,
  options: [
    {
      name: '1 месяц',
      duration: 30,
      priceFocus: 999,
      priceOutOfFocus: 1199,
    },
    {
      name: '3 месяца',
      duration: 90,
      priceFocus: 2599,
      priceOutOfFocus: 3199,
    },
    {
      name: '12 месяцев',
      duration: 360,
      priceFocus: 9999,
      priceOutOfFocus: 13499,
    }
  ]
};

const dummySubscriptionPlanBasicBlocked = {
  uuid: null,
  name: 'Базовая подписка',
  numberOfTexts: 'X',
  numberOfAudios: 'X',
  options: [
    {
      name: '1 месяц',
      duration: 30,
      priceFocus: 'X',
      priceOutOfFocus: 'X',
    },
    {
      name: '3 месяца',
      duration: 90,
      priceFocus: 'X',
      priceOutOfFocus: 'X',
    },
    {
      name: '12 месяцев',
      duration: 360,
      priceFocus: 'X',
      priceOutOfFocus: 'X',
    }
  ]
};

const dummySubscriptionPlanAdvancedBlocked = {
  uuid: null,
  name: 'Продвинутая подписка',
  numberOfTexts: 'X',
  numberOfAudios: 'X',
  options: [
    {
      name: '1 месяц',
      duration: 30,
      priceFocus: 'X',
      priceOutOfFocus: 'X',
    },
    {
      name: '3 месяца',
      duration: 90,
      priceFocus: 'X',
      priceOutOfFocus: 'X',
    },
    {
      name: '12 месяцев',
      duration: 360,
      priceFocus: 'X',
      priceOutOfFocus: 'X',
    }
  ]
};

export const dummySubscriptionPlans = [
  dummySubscriptionPlanCheap,
  dummySubscriptionPlanBasic,
  dummySubscriptionPlanAdvanced
]


export const dummySubscriptionPlansBlocked = [
  dummySubscriptionPlanBasicBlocked,
  dummySubscriptionPlanAdvancedBlocked
]

export const SubscriptionCard = ({ subscriptionPlan, userData, email, handlePurchaseAction, handleShow, setModalLink, setModalName, setModalDuration}) => {
    const url = `${process.env.REACT_APP_API_PAYMENT}`;

    return (
      <SubscriptionCardContainer>
        <Card.Body>  
          <TopHeader>
            {subscriptionPlan.name}
          </TopHeader>
          <Card.Text>
            <div>
              <SubscriptionDescription><SubscriptionNumber>{subscriptionPlan.numberOfTexts}</SubscriptionNumber>Текстовых сказок</SubscriptionDescription>
              <SubscriptionDescription><SubscriptionNumber>{subscriptionPlan.numberOfAudios}</SubscriptionNumber>Аудио-сказок</SubscriptionDescription>
            </div>
          </Card.Text>
          <Stack gap={2}>

            {subscriptionPlan.options.map((option) => (
              <ButtonSubscriptionChoice variant="primary" disabled={!email} onClick={() => { 
                const modalLink = handlePurchaseAction(subscriptionPlan, userData, email, option, setModalLink)
                console.log(modalLink)
                setModalName(subscriptionPlan.name);
                setModalDuration(option.name)
                // setModalLink(modalLink);
                handleShow();
                }
              }>
              <SubscriptionOptions>
                <div>{option.name}</div>
                <SubscriptionPrice>
                  <SubscriptionPriceFocus>{option.priceFocus}</SubscriptionPriceFocus>
                  <SubscriptionPriceOutOfFocus>{option.priceOutOfFocus}</SubscriptionPriceOutOfFocus>
                </SubscriptionPrice>
              </SubscriptionOptions>
            </ButtonSubscriptionChoice>
            ))}
          </Stack>
        </Card.Body>
      </SubscriptionCardContainer>
    );
  };