import React from "react";
import styled from "styled-components";

const DivWrapperContainer = styled.div`
  background-color: #fcfcfc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

const DivContainer = styled.div`
  background-color: #fcfcfc;
  height: 932px;
  overflow: hidden;
  position: relative;
  width: 430px;
`;

const OverlapContainer = styled.div`
  height: 49px;
  left: 17px;
  position: absolute;
  top: 30px;
  width: 170px;
`;

const RectangleContainer = styled.div`
  background: linear-gradient(180deg, rgb(70, 196.17, 232) 26.4%, rgb(203, 75, 227) 100%);
  border-radius: 50px;
  height: 49px;
  left: 29px;
  position: absolute;
  top: 0;
  width: 112px;
`;

const TextWrapperContainer = styled.div`
  color: #ffffff;
  font-family: "Kodchasan-SemiBold", Helvetica;
  font-size: 22px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 8px;
  white-space: nowrap;
  width: 170px;
`;

const EllipseContainer = styled.div`
  background-color: #ffffff;
  border-radius: 1.5px;
  height: 3px;
  left: 59px;
  position: absolute;
  top: 25px;
  width: 3px;
`;

const Ellipse2Container = styled.div`
  background-color: #ffffff;
  border-radius: 1.5px;
  height: 3px;
  left: 99px;
  position: absolute;
  top: 25px;
  width: 3px;
`;

const TextWrapper2Container = styled.div`
  color: #3e3e3e;
  font-family: "Montserrat Alternates-SemiBold", Helvetica;
  font-size: 45px;
  font-weight: 600;
  left: 43px;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  top: 123px;
  width: 349px;
`;

const GroupContainer = styled.div`
  height: 187px;
  left: 27px;
  position: absolute;
  top: 352px;
  width: 379px;
`;

const OverlapGroupContainer = styled.div`
  background: linear-gradient(180deg, rgb(70, 196.17, 232) 26.4%, rgb(203, 75, 227) 100%);
  border-radius: 24px;
  height: 97px;
  left: 19px;
  position: absolute;
  top: 90px;
  width: 337px;
`;

const TextWrapper3Container = styled.div`
  color: #ffffff;
  font-family: "Montserrat Alternates-SemiBold", Helvetica;
  font-size: 25px;
  font-weight: 600;
  left: 35px;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 24px;
  width: 267px;
`;

const PContainer = styled.p`
  color: #3e3e3e;
  font-family: "Montserrat Alternates-SemiBold", Helvetica;
  font-size: 25px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 375px;
`;

const Group2Container = styled.div`
  height: 175px;
  left: -62px;
  position: absolute;
  top: 792px;
  transform: rotate(-180deg);
  width: 492px;
`;

const SubtractContainer = styled.img`
  height: 100px;
  left: 0;
  position: absolute;
  top: 35px;
  transform: rotate(180deg);
  width: 135px;
`;

const ImgContainer = styled.img`
  height: 119px;
  left: 359px;
  position: absolute;
  top: 42px;
  transform: rotate(180deg);
  width: 71px;
`;

const Subtract2Container = styled.img`
  height: 50px;
  left: 191px;
  position: absolute;
  top: 112px;
  transform: rotate(180deg);
  width: 50px;
`;

const LetsIconsBackWrapperContainer = styled.div`
  background: linear-gradient(180deg, rgb(70, 196.17, 232) 49.68%, rgb(203, 75, 227) 100%);
  border-radius: 24.5px;
  height: 49px;
  left: 334px;
  position: absolute;
  top: 30px;
  width: 49px;
`;

const LetsIconsBackContainer = styled.img`
  height: 30px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 27px;
`;

export const AccountStyled = () => {
  return (
    <DivWrapperContainer>
      <DivContainer>
        <OverlapContainer>
          <RectangleContainer />
          <TextWrapperContainer>YoStory</TextWrapperContainer>
          <EllipseContainer />
          <Ellipse2Container />
        </OverlapContainer>
        <TextWrapper2Container>Мой аккаунт</TextWrapper2Container>
        <GroupContainer>
          <OverlapGroupContainer>
            <TextWrapper3Container>
              Оформить
              <br />
              подписку
            </TextWrapper3Container>
          </OverlapGroupContainer>
          <PContainer>
            У Вас пока нет <br />
            подписки
          </PContainer>
        </GroupContainer>
        <Group2Container>
          <SubtractContainer alt="Subtract" src="subtract.svg" />
          <ImgContainer alt="Subtract" src="image.svg" />
          <Subtract2Container alt="Subtract" src="subtract-2.svg" />
        </Group2Container>
        <LetsIconsBackWrapperContainer>
          <LetsIconsBackContainer alt="Lets icons back" src="lets-icons-back.svg" />
        </LetsIconsBackWrapperContainer>
      </DivContainer>
    </DivWrapperContainer>
  );
};

export default AccountStyled;