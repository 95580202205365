import * as React from 'react';
import { useState } from 'react';
import styled from "styled-components";

import axios from 'axios';

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';

import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';

import { Link } from 'react-router-dom';

import { SubscriptionCard, dummySubscriptionPlans, dummySubscriptionPlansBlocked } from '../../elements/SubscriptionCard';
import { ButtonGradient, ButtonSmallGradient } from '../../elements/Buttons';
// const API_ENDPOINT_DETECT_IMAGE = `${process.env.REACT_APP_API_HOST_IP}/api/v1/detect/image`
import { TopHeader, SectionHeader, ParagraphText, ParagraphTextShort} from '../../elements/Text';

import { handlePurchaseAction } from '../../actions/payment';


const PaymentLink = styled.a`
  color: #ffffff;
  font-family: "MontserratAlternates";
  font-weight: bold;
  outline: none;
  text-decoration: none;
  -moz-outline-style: none; 

  :active, :focus { outline: none; -moz-outline-style: none; }

`

const Subscription = ({userData}) => {

  const [show, setShow] = useState(false);
  const [modalLink, setModalLink] = useState(false);
  const [modalName, setModalName] = useState();
  const [modalDuration, setModalDuration] = useState();
  const [email, setEmail] = useState();
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleOpenPageByModalLink = () => window.open(modalLink, "_blank");
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <>
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <ParagraphText>
            Оплата заказа
          </ParagraphText></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ParagraphText>Вы хотите оформить подписку</ParagraphText>
        <ParagraphText><strong>«{modalName}»</strong> на <strong>{modalDuration}</strong></ParagraphText>
        <PaymentLink href={modalLink}><ButtonGradient variant="primary">Перейти на страницу оплаты</ButtonGradient></PaymentLink></Modal.Body>
      </Modal>
    <Stack gap={2} className="col-md-5 mx-auto">
      <center>
        <TopHeader>Оформить подписку</TopHeader>
      </center>
      <div>
        <Form>
          <Form.Group className="mb-3" controlId="email-form.ControlInput1">
            <Form.Label>Введите ваш email</Form.Label>
            <Form.Control type="email" placeholder="name@example.com" value={email} onChange={handleEmailChange} />
          </Form.Group>
        </Form>
      </div>
      {dummySubscriptionPlans.map((subscriptionPlan) => (
        <SubscriptionCard subscriptionPlan={subscriptionPlan} userData={userData} email={email} handlePurchaseAction={handlePurchaseAction} handleShow={handleShow} setModalLink={setModalLink} setModalName={setModalName} setModalDuration={setModalDuration}/>
      ))}
    </Stack>
    </>
  );
}

export default Subscription;