import * as React from 'react';
import axios from 'axios';

import { Link } from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import { TopHeader, SectionHeader, SectionHeaderLeft, ParagraphText, ParagraphTextShort} from '../../elements/Text';

// const API_ENDPOINT_DETECT_IMAGE = `${process.env.REACT_APP_API_HOST_IP}/api/v1/detect/image`

const UserAgreement = () => {
 
  return (
    <>
      <Stack gap={2} className="col-md-5 mx-auto">
        <center>
          <TopHeader>Пользовательское соглашение Бота YoStory</TopHeader>
          <ParagraphTextShort>
            Настоящее Соглашение (далее – Соглашение) регулирует правоотношения между ИП Соколовым Михаилом Михайловичем (ОГРНИП 320774600101097, ИНН: 771570467307) (далее – Администратор), в ведении которого находится чат-бот в мессенджере «Telegram» по адресу <Link to={"https://t.me/yostory_bot"}>@yostory_bot</Link> (<Link to={"https://t.me/yostory_bot"}>https://t.me/yostory_bot</Link>) (далее – Бот), и любым лицом, намеревающемся использовать Бота, независимо от целей такого использования (далее – Пользователь), на обозначенных настоящим Соглашением условиях.
          </ParagraphTextShort>
          <SectionHeaderLeft>
            1. Общие положения
          </SectionHeaderLeft>
          <ParagraphTextShort>
            1.1. Используя Бота, Пользователь полностью и безоговорочно принимает условия настоящего Соглашения и Политики обработки персональных данных при использовании Бота и Веб-сайта YoStory (далее – Политика). 
          </ParagraphTextShort>
          <ParagraphTextShort>
            1.2. Пользователь, который использует Бота, считается ознакомленным с условиями Соглашения и Политики в полном объеме, и полностью и безоговорочно принимает их условия. В свою очередь, Администратор обязуется обеспечивать для Пользователей постоянную возможность изучить положения Соглашения и Политики путем предоставления постоянного свободного доступа к ним в действующей редакции.
          </ParagraphTextShort>
          <ParagraphTextShort>
            1.3. Использование материалов и сервисов Бота регулируется нормами действующего законодательства Российской Федерации.
          </ParagraphTextShort>
          <ParagraphTextShort>
            1.4. Продолжая использовать Бота, Пользователь подтверждает, что не пребывает в состоянии заблуждения (существенного либо несущественного) относительно характеристик и функционала Бота, условий Соглашения и Политики и своих собственных действий. 
          </ParagraphTextShort>
          <ParagraphTextShort>
            Администратор прикладывает разумные и достаточные усилия к тому, чтобы Бот работал исправно и предоставлял все заявленные в его описании услуги корректно и в надлежащий срок. При этом Администратор не гарантирует, что Бот не содержит ошибок, будет удовлетворять всем требованиям Пользователя, или что Бот будет функционировать должным образом при использовании совместно с любым другим программным или аппаратным обеспечением.
          </ParagraphTextShort>
          <ParagraphTextShort>
            Администратор не гарантирует результаты, которые Пользователь может получить при использовании Бота. Все риски, связанные с качеством работы и производительностью Бота, возлагаются на Пользователя.
          </ParagraphTextShort>
          <ParagraphTextShort>
            1.5. Пользователь, полностью либо частично не согласный с условиями Соглашения, не вправе Использовать Бота, и обязан немедленно прекратить такое использование.
          </ParagraphTextShort>
          <SectionHeaderLeft>
            2. Предмет соглашения
          </SectionHeaderLeft>
          <ParagraphTextShort>
            2.1. В соответствии с Соглашением, Администратор оказывает Пользователям, принявшим Соглашение, услугу по предоставлению права использования Бота, в порядке и на условиях, предусмотренных Соглашением. К сервисам, предоставляемым Ботом, в том числе относятся:
          </ParagraphTextShort>
          <ParagraphTextShort>
            <ul>
              <li>формирование уникальных текстовых и аудио-материалов, созданных при помощи компьютерных технологий, в том числе с использованием искусственного интеллекта (нейросети);</li>
              <li>доступ к материалам Бота;</li>
              <li>доступ к навигации Бота с правом направления в адрес Администратора оценок и отзывов об услугах, предоставляемых Ботом.</li>
            </ul>
          </ParagraphTextShort>
          <ParagraphTextShort>
            2.2. Администратор вправе вносить в Соглашение изменения, которые вступают в силу с момента публикации, и не требуют какого-либо предварительного уведомления (одобрения, согласия) Пользователей. Соглашение в новой редакции действует как для новых Пользователей, так и для Пользователей, принявших условия Соглашения до внесения изменений.
          </ParagraphTextShort>
          <ParagraphTextShort>
            2.3. В случае если Администратором были внесены какие-либо изменения в Соглашение, в порядке, предусмотренном п. 2.2 Соглашения, с которыми Пользователь не согласен, он обязан прекратить использование Ботом.
          </ParagraphTextShort>
          <SectionHeaderLeft>
            3. Права и обязанности сторон 
          </SectionHeaderLeft>
          <ParagraphTextShort>
            3.1. Администратор обязуется:
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.1.1. Действовать открыто, добросовестно, не нарушать условия Соглашения.
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.1.2. Прилагать все усилия для предоставления Пользователю постоянного доступа к Боту в целях использования Бота в объеме, порядке и на условиях, предусмотренных Соглашением.
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.1.3. Исполнять другие обязательства, предусмотренные Соглашением и применимым правом.
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.2. Администратор вправе:
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.2.1. Приостановить обеспечение постоянного доступа к Боту (либо у его отдельным функциям и/или сервисам) в объеме, порядке и на условиях, предусмотренных Соглашением, в случае сбоев в работе или ограничения доступа в связи с обслуживанием, модификациями, удалением, расширениями или вводом дополнительных функций, или в связи с системными нарушениями в Интернете (в том числе на сервере), в случае форс-мажорных или иных обстоятельств.
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.2.2. В любое время в одностороннем порядке и без необходимости направления соответствующего уведомления Пользователю и получения от Пользователя какого-либо согласования:
          </ParagraphTextShort>
          <ParagraphTextShort>
            <ul>
              <li>удалять и/или вносить изменения в функционал и материалы Бота.</li>
              <li>передать свои права и обязательства по настоящему Соглашению третьей стороне.</li>
            </ul>
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.2.3. При выявлении любых ошибок (некорректной и(или) неправильной работе) Бота, его сервисах, устранять последствия таких ошибок.
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.2.4. Отказывать в доступе к Боту без объяснения причин, в том числе в случае нарушения Пользователем условий настоящего Соглашения.
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.2.5. В любой момент менять стоимость и условия подписок на Бота. Изменение стоимости не распространяется на уже оплаченные подписки на момент внесения изменений.
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.2.6. Реализовывать иные права, предусмотренные Соглашением и применимым правом.
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.3. Пользователь обязуется:
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.3.1. Действовать открыто, добросовестно, не нарушать условия Соглашения.
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.3.2. Самостоятельно проверять Соглашение на предмет изменений. Неосуществление Пользователем действий по ознакомлению с Соглашением и/или измененной редакцией Соглашения, приложений к нему, не может служить основанием для неисполнения Пользователем своих обязательств и несоблюдения Пользователем ограничений, установленных настоящим Соглашением. Фактическое использование Бота Пользователем после внесения изменений в условия Соглашения означает согласие Пользователя с новыми условиями.
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.3.3. Сообщить Администратору о любых ошибках (некорректной и(или) неправильной работе) Бота, его сервисов, выявленных во время использования Бота. При этом под ошибками понимается любой результат от действий (бездействий) Пользователя по использованию Бота, наступление которого противоречит условиям, описанным в Боте, либо обычно применяемой практике. В том случае, если ошибка в Боте привела к получению Пользователем любой выгоды от такой ошибки, Пользователь не вправе использовать полученную выгоду, и обязуется немедленно сообщить об ошибке и ее результатах технической поддержке Администратора.
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.3.4. Исполнять другие обязательства, предусмотренные Соглашением, и применимым правом.
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.4. Пользователь вправе:
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.4.1. Использовать функционал Бота в личных некоммерческих целях в объеме, способом и на условиях, указанных в Соглашении.
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.4.2. Реализовывать иные права, предусмотренные Соглашением и применимым правом.
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.5. Пользователь не вправе:
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.5.1. Использовать Бота в нарушение условий Соглашения.
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.5.2. Распространять, продавать, переводить, модифицировать, осуществлять обратный инжиниринг или выполнять обратную компиляцию или декомпиляцию, дизассемблирование или создание производных работ от Бота, его функционала, сервисов и материалов.
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.5.3. Осуществлять попытки получить несанкционированный доступ к другим компьютерным системам или сетям, к которым подключен Бот.
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.5.4. Вмешиваться и предпринимать действия, которые приведут к вмешательству или нарушению работы Бота, серверов, на которых размещен Бот.
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.5.5. Обходить любое шифрование или другие средства безопасности, используемые Ботом.
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.5.6. Использовать технологии "дата майнинг" (data mining), «скрапинг» (scraping) или аналогичные инструменты сбора и извлечения данных из Бота.
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.5.7. Продавать, сдавать в аренду, передавать, распространять, использовать в качестве оказания услуг или иным образом передавать третьим лицам доступ к использованию функционала Бота, который предоставлен Пользователю по условиям настоящего Соглашения.
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.5.8. Осуществлять иные действия, которые могут отрицательно повлиять на корректную работу Боту, а также репутацию и доброе имя Администратора и других Пользователей.
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.5.9. Использовать любые материалы Бота, а также результаты использования Бота в виде созданных им текстовых и аудио-материалов в коммерческих целях. Если Пользователь намеревается использовать указанные материалы, созданные Ботом, в коммерческих целях, он обязан согласовать в письменном виде такое использование с Администратором.
          </ParagraphTextShort>
          <ParagraphTextShort>
            3.5.10. Совершение относительно Бота других действий, нарушающих нормы действующего законодательства.
          </ParagraphTextShort>
          <SectionHeaderLeft>
            4. Исключительные права на интеллектуальную собственность
          </SectionHeaderLeft>
          <ParagraphTextShort>
            4.1. Бот, функционал Бота и материалы, размещенные в Боте, являются объектами исключительных прав Администратора и/или других правообладателей.
          </ParagraphTextShort>
          <ParagraphTextShort>
            4.2. Пользователь вправе использовать материалы, созданные Ботом, исключительно в личных некоммерческих целях. Это означает, что использование таких материалов исключает все коммерческие цели, а именно любое извлечение личной выгоды Пользователем, в том числе:
          </ParagraphTextShort>
          <ParagraphTextShort>
            <ul>
              <li>использование материалов в рекламных и маркетинговых целях</li>
              <li>любое распространение материалов за плату или иное встречное предоставление;</li>
              <li>любое использование материалов в качестве части продукта/услуги, предназначенных для продажи за плату или иное встречное предоставление.</li>
            </ul>
          </ParagraphTextShort>
          <ParagraphTextShort>
            4.3. Для использования материалов, созданных Ботом, в коммерческих целях Пользователь обязан заключить отдельное соглашение с Администратором, содержащее все условия такого использования. Для получения более детальной информации о коммерческом использовании Бота пользователь может связаться с Администратором в порядке, указанном в п. 7.1 Соглашения.
          </ParagraphTextShort>
          <ParagraphTextShort>
            4.4. Используя Бота, Пользователь предоставляет Администратору бессрочную, неисключительную, безвозмездную, безотзывную лицензию на воспроизведение, создание производных работ, публичное распространение, предоставление сублицензий на текстовую и любую иную информацию, которую Пользователь вводит в Бота, а также любых материалов, созданных Пользователем с помощью Бота. Данная лицензия сохраняет свое действие после прекращения настоящего Соглашения любой из сторон.
          </ParagraphTextShort>
          <SectionHeaderLeft>
            5. Гарантии и ответственность
          </SectionHeaderLeft>
          <ParagraphTextShort>
            5.1. Ответственность Администратора:
          </ParagraphTextShort>
          <ParagraphTextShort>
            5.1.1. Администратор не несет никаких обязательств и ответственности в связи с качеством доступа Пользователя к сети Интернет и качеством Интернет-соединения, а также в связи с наличием/отсутствием у Пользователя оборудования и программного обеспечения для доступа в Интернет и качеством такого оборудования и программного обеспечения.
          </ParagraphTextShort>
          <ParagraphTextShort>
            5.1.2. Администратор не несет ответственности за сбои или иные проблемы в работе телефонных сетей или служб, компьютерных систем, серверов или провайдеров, компьютерного или телефонного оборудования, программного обеспечения, а также за сбои в работе электронной почты или скриптов (программ).
          </ParagraphTextShort>
          <ParagraphTextShort>
            5.1.3. Администратор не несет ответственности за какие-либо задержки, сбои, неверную или несвоевременную доставку, удаление или потерю каких-либо пользовательских данных или иной информации, передаваемой Пользователем в адрес Администратора, в том числе, с использованием доступного функционала Бота. Совершая указанные действия, Пользователь гарантирует, что имеет резервные копии всей отправляемой информации.
          </ParagraphTextShort>
          <ParagraphTextShort>
            5.1.4. Администратор не несет перед Пользователем никакой ответственности за убытки, вынужденные перерывы в деловой активности, потерю пользовательских и иных данных, за расходы (реальные, косвенные, опосредованные), случайные убытки, упущенную выгоду, недополученные доходы, вызванные использованием и/или связанные с использованием Бота.
          </ParagraphTextShort>
          <ParagraphTextShort>
            5.1.5. Администратор не несет ответственности за содержание информации, сгенерированной Ботом. Генерация осуществляется в полностью автоматическом режиме, Администратор не проводит (и не имеет технической возможности проводить) проверку корректности созданного материала и его соответствие запросу Пользователя. При этом Администратор подтверждает, что принял все доступные ему меры для недопущения генерации Ботом материалов, содержащих сцены сексуального характера, насилия и обсценной лексики.
          </ParagraphTextShort>
          <ParagraphTextShort>
            5.1.6. Пользователь самостоятельно несет ответственность за соответствие запроса действующему законодательству.
          </ParagraphTextShort>
          <ParagraphTextShort>
            5.1.7. Пользователь осознает, что информация, сгенерированная Ботом, создается с использованием технологий искусственного интеллекта, в связи с чем такая информация может не обладать уникальностью и совпадать полностью или частично с информацией, сгенерированной Ботом по Запросам других Пользователей.
          </ParagraphTextShort>
          <ParagraphTextShort>
            5.1.8. Администратор не несет перед Пользователем никакой ответственности за убытки, вызванные возможными ошибками, опечатками и некорректной работой Бота. Вместе с тем Администратор обязуется принимать разумные меры для недопущения и исправления таких ошибок, опечаток и некорректной работы.
          </ParagraphTextShort>
          <SectionHeaderLeft>
            6. Прочие условия
          </SectionHeaderLeft>
          <ParagraphTextShort>
            6.1. Применимое право к отношениям Сторон по Соглашению: право Российской Федерации.
          </ParagraphTextShort>
          <ParagraphTextShort>
            6.2. Настоящее Соглашение действует в течение всего срока использования Бота.
          </ParagraphTextShort>
          <ParagraphTextShort>
            6.3. Все возможные споры, вытекающие из настоящего Соглашения или связанные с ним, подлежат разрешению в соответствии с действующим законодательством Российской Федерации.
          </ParagraphTextShort>
          <ParagraphTextShort>
            6.4. Признание судом какого-либо положения Соглашения недействительным или не подлежащим принудительному исполнению не влечет недействительности иных положений Соглашения.
          </ParagraphTextShort>
          <ParagraphTextShort>
            6.5. Бездействие со стороны Администратора в случае нарушения кем-либо из Пользователей положений Соглашения не лишает Администратора права предпринять позднее соответствующие действия в защиту своих интересов и защиту авторских прав на охраняемые в соответствии с законодательством материалы Бота.
          </ParagraphTextShort>
          <ParagraphTextShort>
            6.6. Администратор вправе в любое время в одностороннем порядке изменять условия настоящего Соглашения. Такие изменения вступают в силу с момента размещения новой версии Соглашения по адресу: <Link to={"https://www.yostory.ru/user-agreement"}>https://www.yostory.ru/user-agreement</Link>. При несогласии Пользователя с внесенными изменениями он обязан прекратить использование Бота, включая его материалы и сервисы.
          </ParagraphTextShort>
          <SectionHeaderLeft>
            7. Контактная информация
          </SectionHeaderLeft>
          <ParagraphTextShort>
            7.1. По всем вопросам Вы можете связаться с нами через кнопку «Помощь» в Боте.
          </ParagraphTextShort>
          <ParagraphTextShort>
            <i>Дата публикации: 01.08.2023.</i>
          </ParagraphTextShort>
        </center>
      </Stack>
    </>
  );
}

export default UserAgreement;