import * as React from 'react';
import axios from 'axios';

import { Link } from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import { ButtonGradient } from '../../elements/Buttons';
// const API_ENDPOINT_DETECT_IMAGE = `${process.env.REACT_APP_API_HOST_IP}/api/v1/detect/image`
import { TopHeader, SectionHeader, ParagraphText, ParagraphTextShort} from '../../elements/Text';

const LegalNotice = () => {
 
  return (
    <>
      <Stack gap={2} className="col-md-5 mx-auto justify-content-between" style={{ height: '60vh', display: 'flex', direction: 'column', justifyContent: 'space-between'}}>
        <center style={{ height: '30vh'}}>
          <TopHeader>Правовая информация</TopHeader>
        </center>
        <Stack gap={2} >
          <ButtonGradient as={Link} to="privacy-policy" variant="primary">
            Политика обработки персональных данных
          </ButtonGradient>
          <ButtonGradient as={Link} to="user-agreement" variant="primary">
            Пользовательское соглашение
          </ButtonGradient>
        </Stack>        
      </Stack>
    </>
  );
}

export default LegalNotice;