import React from "react";
import styled from "styled-components";

export const TopHeader = styled.h2`
	text-align: center;
	font-weight: bold;
`
export const SectionHeader = styled.h3`
	width: 75%;
	text-align: center;
	font-weight: bold;
`
export const SectionHeaderLeft = styled.p`
	width: 75%;
	text-align: left;
	font-weight: bold;
`
export const ParagraphText = styled.p`
	text-align: left;
`
export const ParagraphTextShort = styled.p`
	width: 75%;
	text-align: left;
`