import * as React from 'react';
import axios from 'axios';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ButtonGradient } from '../../elements/Buttons';
// const API_ENDPOINT_DETECT_IMAGE = `${process.env.REACT_APP_API_HOST_IP}/api/v1/detect/image`

const Support = () => {
 
  return (
    <>
      <ButtonGradient variant="primary" type="submit">
        Обратиться в поддержку
      </ButtonGradient>
    </>
  );
}

export default Support;