import * as React from 'react';
import axios from 'axios';

import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import { ButtonGradient } from '../../elements/Buttons';
// const API_ENDPOINT_DETECT_IMAGE = `${process.env.REACT_APP_API_HOST_IP}/api/v1/detect/image`
import { TopHeader, SectionHeader, ParagraphText, ParagraphTextShort} from '../../elements/Text';

const Referral = () => {
 
  return (
    <>
      <Stack gap={2} className="col-md-5 mx-auto justify-content-between" style={{ height: '80vh', display: 'flex', direction: 'column', justifyContent: 'space-between'}}>
        <center>
          <TopHeader>Реферальная программа</TopHeader>
        </center>
        <div style={{ height: '60vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
            <SectionHeader>Ваша персональная реферальная ссылка</SectionHeader>
            <ButtonGradient variant="primary"><div style={{ fontSize: '0.7em'}}>https://t.me/yostory_bot=185945198</div></ButtonGradient>
            <ParagraphTextShort style={{paddingTop: '1em'}}>
                Отправляйте реферальную ссылку друзьям, и получайте бонусы от YoStory за каждого друга, оформившего подписку!
            </ParagraphTextShort>
        </div>
      </Stack>
    </>
  );
}

export default Referral;